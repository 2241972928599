
import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'



function handleImage(props) {
 if (props.mainImage) return (
  <Link to={`./news_and_insights/dynamic/${props.slug.current}`}>
   <Img fluid={props.mainImage.asset.fluid} />
  </Link >
 )
 else {
  return null
 }
}

function ArticleUnit(props) {

 return (
  <div className="ctr-page-listing-unit" key={props.id}>

   {handleImage(props)}

   <h3>{props.title}</h3>
   <p>{props.shortContent}</p>
   <Link to={`./news_and_insights/dynamic/${props.slug.current}`}>Read more <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
  </div >

 )
}

export default ArticleUnit