import { Link } from 'gatsby'
import React from 'react'

import ArticleUnit from './article-unit'


function ArticleList(props) {
  console.log(props.nodes)

  return (
    <>
      {
        props.nodes &&
        props.nodes.map(node => (
          <React.Fragment key={node.id} >
            <ArticleUnit {...node} />
          </React.Fragment>
        ))
      }
    </>
  )
}

export default ArticleList


