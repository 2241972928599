import React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ArticleList from "../../components/article-list"

import "../../styles/secondary_pages.scss"


import { Helmet } from "react-helmet"

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs
} from '../../lib/helpers'



export const query = graphql`
  query InsightsArticleList {
    posts: allSanityPost(sort: {fields: publishedAt, order: DESC}, filter: {categories: {elemMatch: {id: {}, title: {eq: "News and Insights"}}}}) {
      edges {
        node {
          id
          title
          _createdAt(fromNow: false)
          slug {
            current
          }
          publishedAt
         shortContent
          mainImage {
          asset {
            originalFilename
            path
            url
            fluid(maxWidth: 368, sizes: "184, 368") {
              ...GatsbySanityImageFluid
            }
          }
        }
        }
      }
    }
  }
`


const SecondPage = props => {

  const { data, errors } = props

  const nodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
    : []

  if (errors) {
    return (
      <Layout>
        Big Error Here

   </Layout>
    )
  }


  return (
    <Layout styleClass="insights">
      <SEO title="Insights" />
      <section id="sct-pg-hdr" className="container ctr-page-header">
        <h2>Insights</h2>
        <p>Learn how you and your practice can get more out of MSM</p>
      </section>
      <section className="ctr-page-listing">
        <ArticleList nodes={nodes} />
      </section>

    </Layout>
  )

}

const ComponentName = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>



export default SecondPage
